<template>
  <b-sidebar
    id="opened-value-form"
    :visible="openedValueForm"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:opened-value-form', val)"
  >
    <template>
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('form.value.label') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <b-card
        v-if="value && value.title"
        class="card-value"
      >
        <!-- BODY -->

        <b-tabs>
          <b-tab
            v-for="language in languages"
            :key="language.code"
          >
            <template #title>
              <b-img
                :src="require('@/assets/images/flags/' + language.code + '.svg')"
                height="16"
                width="16"
                class="mr-1"
              />
              <span class="d-none d-sm-inline">{{ language.title }}</span>
            </template>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.title.label')"
                  :label-for="'value.title.' + language.code"
                >
                  <b-form-input
                    :id="'value.title.' + language.code"
                    v-model="value.title[language.code]"
                    :state="errors && errors.title && errors.title[language.code] ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors['value.title.' + language.code]">
                    {{ errors.title[language.code][0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.slug.label')"
                  :label-for="'value.slug.' + language.code"
                >
                  <b-form-input
                    :id="'value.slug.' + language.code"
                    v-model="value.slug"
                    :state="errors && errors.slug ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.slug">
                    {{ errors.slug[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  :label="$t('form.enabled_for_filter.label')"
                  :label-for="'value.enabled_for_filter.' + language.code"
                >
                  <b-form-checkbox
                    :id="'value.enabled_for_filter.' + language.code"
                    v-model="value.enabled_for_filter"
                    :state="errors && errors.enabled_for_filter ? false : null"
                  />
                  <b-form-invalid-feedback v-if="errors && errors.enabled_for_filter">
                    {{ errors.enabled_for_filter[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>

        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              block
              @click="submit"
            >
              {{ $t('general.save') }}
            </b-button>
          </b-col>
        </b-row>
      </b-card>

    </template>
  </b-sidebar>
</template>

<script>
import { mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {
  BButton,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BRow,
  BTabs,
  BTab,
  BImg,
  BSidebar,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BTabs,
    BTab,
    BImg,
    BSidebar,
    BFormCheckbox,
  },
  model: {
    prop: 'openedValueForm',
    event: 'update:opened-value-form',
  },
  props: {
    openedValueForm: {
      type: Boolean,
      required: true,
    },
    value: {
      type: Object,
      required: true,
    },
    attributeId: {
      type: Number,
      required: true,
    },
    languages: {
      type: [Array],
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      errors: 'validation/errors',
    }),
  },
  destroyed() {
    this.$store.dispatch('validation/clearErrors')
  },
  methods: {
    hide() {
      this.$store.dispatch('validation/clearErrors')
      this.$emit('update:opened-value-form', false)
      this.value = {}
    },
    submit() {
      const data = {
        title: this.value.title,
        slug: this.value.slug,
        enabled_for_filter: this.value.enabled_for_filter,
      }
      if (!this.value.id) {
        this.$http.post(`/api/attributes/${this.attributeId}/values/`, data)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.value = {}
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      } else {
        this.$http.put(`/api/attributes/${this.attributeId}/values/${this.value.id}`, data)
          .then(() => {
            this.$store.dispatch('validation/clearErrors')
            this.$emit('refetch-data')
            this.$emit('update:opened-value-form', false)
            this.value = {}
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Success',
                icon: 'AlertCircleIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#opened-value-form {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
